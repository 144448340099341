import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo.js";
import "../styles/index.scss";

const IndexPage = () => {
  // States to control the moving DIV of bar1...bar4
  const [bar1Container, setBar1Container] = useState("hideInfoContainer");
  const [bar2Container, setBar2Container] = useState("hideInfoContainer");
  const [bar3Container, setBar3Container] = useState("hideInfoContainer");
  const [isStart, setIsStart] = useState(true);
  const infoBarHeight = 800;
  const navBarHeight = 105;

  // This function handles which bar's DIV start to move depending on the scroll position
  function moveIt() {
    const appDiv = document.querySelector(".layoutContainer");
    let top = appDiv.getBoundingClientRect().top;

    // Setting up the classNames of bars depending on the scroll position

    setBar1Container(
      top <= 0 && top >= (infoBarHeight * 0.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );

    setBar2Container(
      top <= (infoBarHeight * 0.5 + navBarHeight) * -1 &&
        top >= (infoBarHeight * 1.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );

    setBar3Container(
      top <= (infoBarHeight * 1.2 + navBarHeight) * -1 &&
        top >= (infoBarHeight * 2.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );
  }
  // To create a delay of displaying infoBa1 during the start
  if (isStart) {
    setBar1Container("hideInfoContainer");
    setIsStart(false);
    setTimeout(() => {
      setBar1Container("showInfoContainer");
    }, 500);
  }

  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    window.onscroll = moveIt;
  }

  return (
    <Layout headOneOfPage="A Moringa Oleifera, a csodafa és a Moringa tea.">
      <Seo title="Moringa Oleifera, a csodafa" lang="hu" />
      <main>
        {/* First info bar - the main text is on the LEFT side with animation */}
        <div className="info bar1">
          <article className={`infoContainer ${bar1Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">HASZNÁLD FEL</div>
                <div className="infoSubTitle">a természet erejét !</div>
              </h2>
            </header>

            <p className="infoText">
              Testünket folyamatosan bombázzák külső hatások. Ilyenek pédául a
              NapUV sugárzása, vagy a baktériumok, vírusok, de ilyenek az emberi
              tevékenység olyan következményei is, mint a vegyianyagokkal
              szennyezett levegő, a talaj és a víz vagy a különféle
              élelmiszeripari adalékanyagok. <br />
              Egészségünket és életartalmunkat ezek a negatív hatások
              erőteljesen képesek befolyásolni. Ne várd meg, amíg baj lesz!
              <b> A Moringa Oleifera, a csodafa is segíthet!</b>
            </p>
          </article>
        </div>

        {/* Second info bar - the main text , is on the RIGHT side with animation */}
        <div className="info bar2">
          <article className={`infoContainer ${bar2Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">VÉDD MAGAD, VÉDD</div>
                <div className="infoSubTitle">a testedet !</div>
              </h2>
            </header>

            <p className="infoText">
              A sok negatív külső hatás túlterhelheti a testedet és kialakulhat
              az oxidatív stressz állapota és az ebből adódó különféle krónikus
              gyulladások. <br />A tested ugyan erőteljes védelmi vonallal
              rendelkezik ezeknek a fenyegetéseknek a leküzdésére, de van, hogy
              ez nem elegendő és be kell avatkoznod. <br /> <br />
              <b>Egészséges táplálkozással és rendszeres mozgással </b>
              felkészítheted magad és jelentősen megnövelheted a tested védekező
              képességét.
            </p>
          </article>
        </div>

        {/* Third info bar - the main text is on the LEFT side with animation */}
        <div className="info bar3">
          <article className={`infoContainer ${bar3Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">FEDEZD FEL A MORINGA</div>
                <div className="infoSubTitle">csodafa erejét!</div>
              </h2>
            </header>

            <p className="infoText">
              A <b>Moringa Oleifera</b>, ez az Indiában őshonos csodafa akár
              segíthet neked is. Több tápanyagot tartalmaz, mint bármely más
              ismert növény a földön. <br />
              Az ősi indiai orvoslás 300 féle betegség megelőzésére vagy
              kezelésére használta. A Moringa fa különböző részeit - levél,
              virág, kéreg, gyökér -, ma is mind felhasználják a hagyományos
              orvoslásban. Többek között készítenek belőle <b>Moringa teát </b>
              is.
            </p>
            <button className="buttons" style={{ marginLeft: "30px" }}>
              <Link className="buttonLink" to="/benefits/">
                MIÉRT JÓ
              </Link>
            </button>
          </article>
        </div>
        <div className="mainText">
          <article>
            <h2>
              A <b>Moringa Oleifera</b> fa története
            </h2>
            <p>
              A Moringa Oleifera, a csodafa a legismertebb a - tizenhárom fajt
              számláló -, Moringacae félék nemzetségében. A földön eddig
              megismert növények között a legváltozatosabb tápanyag összetételű.
              A Moringa Oleifera már az ókorban is rendkívül értékes növénynek
              tartották. A rómaiak, görögök és az egyiptomiak ehető olajat
              préseltek ki a Moringa magokból, de felhasználták parfümökhöz és
              bőrápolókhoz is. A Himalája lejtőin őshonos fa magjából készült
              olajat már a 19. században is exportálták a nyugat - indiai
              Moringa ültetvényekről. A <b>Moringa olaj</b> elsősorban Európában
              használták alapanyag volt. A <b>Moringa ehető levele</b> már
              évszázadok óta kedvelt Indiában, valamint elterjedt egész
              Nyugat-Afrikában és Ázsia egyes részein. A nyugati világ is kezdi
              újra felfedezni. Többek között
              <b>Moringa tea </b>és <b>Moringa smoothie</b> is készül belőle.
            </p>
            <p>
              i.e. 326-ban Nagy Sándor tovább ment kelet felé azzal a céllal,
              hogy meghódítsa Indiát. Miután átkelt az Indus folyón,
              szembetalálta magát a Mauri birodalom katonáival. Ezek a harcosok
              már rendszeresen, naponta fogyasztották a Moringa leveléből
              készült kivonatot. A mauri katonákat erős, nagy állóképességű és
              bátor harcosoknak ismerték meg a görögök. A Moringa nem csak erőt
              adott nekik, hanem stresszoldó és fájdalomcsillapítóként is
              használták, így bátrabbak lehettek a küzdelemben. Feljegyezték,
              hogy ezeknek a harcosoknak kevesebb alvásra volt szükségük és alig
              betegedtek meg. Habár összesen 60 ütközetben csaptak össze Sándor
              seregeivel, végül a Moringa levél sem mentette meg őket a
              hódítástól. Ennek ellenére Nagy Sándor halálát követően még jó 500
              évig állt fenn a{" "}
              <Link to="https://www.khanacademy.org/humanities/world-history/ancient-medieval/early-indian-empires/a/the-maurya-and-gupta-empires">
                Mauri birodalom
              </Link>{" "}
              , ahol továbbra it fogyasztották a Moringát.
            </p>
          </article>
          <article>
            <h2>
              A <b>Moringa napjainkban</b>
            </h2>
            <p>
              Az 1970-es évek óta sok leírás jelent meg tudományos
              folyóiratokban a{" "}
              <Link to="https://greneraorganics.in">Moringa Oleifera</Link>
              Moringa Oleifera táplálkozási és gyógyászati használatával
              kapcsolatban. A nem csak élelmiszerként történő felhasználásáról
              is sok cikket publikáltak. Több - elsősorban in vitro - tudományos
              kutatás is folyt már a <b>Moringa Oleifera</b> növény különleges
              összetételével és hatásaival kapcsolatban. A növénynek
              gyakorlatilag minden része fogyasztható. A különböző részei
              más-más hatást váltanak ki, hiszen az összetételük is különböző. A
              legertejedtebb fogyasztási formája a
              <Link to="https://okosbazar.hu/collections/indiai-bio-moringa-es-tulsi-teak">
                <b>Moringa tea</b>
              </Link>
              , amelyet a fa leveleinek szárításával készítenek el. A{" "}
              <b>Moringa tea sok vitamint, antioxidánsokat</b> ásványi
              anyagokat, aminosavakat tartalmazó kellemesen édeskés ital.
              <Link to="/ingredients/">Tápanyag összetétele</Link>Tápanyag
              összetétele függ a termesztés helyétől.
            </p>
          </article>
          <article>
            <h2>
              <b>Moringa fajták</b>
            </h2>
            <p>
              A <b>Moringa fa</b>, lombhullató, a szubtrópusi területen megélő,
              aszálynak is ellenárlló 12m-es átlag magasságú fa. A Morinacae
              nemzettségbe az alábbi fajok tartoznak: Arborea Borziana
              Concanensis Drouhardii Hildebrandtii Longituba Ovalifolia
              Peregrina Pygmaea Rivae Ruspoliana Stenopetala Oleifera <br></br>{" "}
              <b>A Moringa Oleifera</b> számos más néven is ismert:{" "}
              <b>Csodafa (Miracle Tree)</b>, Dob fa (<b>Drumstrick Tree</b>),
              Benzolive, Kelor, Marango, Mlonge, Mulangay, Saijhan, Sajna
            </p>
          </article>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
